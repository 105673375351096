<template>
  <tr>
    <wi-list-data-table-actions :config="config" :props="props"></wi-list-data-table-actions>
    <td class="text-xs-left" style="padding: 0 10px !important;" @click="taskAction()">
      <strong v-if="props.item.customer && props.item.customer.name">
        <span v-text="props.item.customer.first_name"></span><br>
        <span style="color: gray;">({{props.item.customer.name}})</span>
      </strong>
      <span v-else>Não definido</span>
    </td>
    <td class="text-xs-left" style="padding: 0px !important" @click="taskAction()">
      <strong v-if="props.item.date_formated"> {{ props.item.date_formated }}</strong>
      <strong v-if="props.item.time_formated"> {{ props.item.time_formated }}</strong>
      <span v-else>Não definido</span>    
    </td>
    <td class="text-xs-left" style="padding: 0px !important" @click="taskAction()">
      <div style="text-align: center;">
        <v-icon v-if="props.item && props.item.type_info" :color="props.item.type_info.color">{{props.item.type_info.icon}}</v-icon><br>
        <strong v-if="props.item && props.item.type_info" :style="{color: props.item.type_info.color}">{{props.item.type_info.title}}</strong>
      </div>
    </td>
    <td style="text-align: left;" @click="taskAction()">
      <div style="text-align: center;">
        <v-icon v-if="props.item && props.item.status_info" :color="props.item.status_info.color">{{props.item.status_info.icon}}</v-icon><br>
        <strong v-if="props.item && props.item.status_info" :style="{color: props.item.status_info.color}">{{props.item.status_info.title}}</strong>
      </div>
    </td>
    <td style="text-align: left;" @click="taskAction()">
      <span>{{props.item.description || ' - - - '}}</span>
    </td>
    <!-- <td class="text-xs-left" style="padding: 0 10px !important;">
      <div v-if="props.item.type.code != 100 && props.item.type.code != 200">
        <v-btn v-if="props.item.business_id" :style="{color: props.item.type.color}" outline small block @click="goToBusiness(props.item)">
          <v-icon v-text="props.item.type.icon"></v-icon>
          <span>Ver <span v-text="props.item.type.title"></span></span>
        </v-btn>
        <v-btn v-else :style="{color: props.item.type.color}" outline small block @click="createNewBusiness(props.item)">
          <v-icon v-text="props.item.type.icon"></v-icon>
          <span>Criar <span v-text="props.item.type.title"></span></span>
        </v-btn>
      </div>
    </td> -->
  </tr>
</template>
<script>
  import WiListDataTableActions from '@/default/component/WiList/WiListDataTableActions'
  export default {
    name: 'Tasks',
    data: () => {
      return {}
    },
    methods: {
      taskAction: function () {
        this.$router.push({
          name: 'task-sale',
          params: {id: this.props.item.id}
        })
      }
    },
    computed: {
      itemStatus: {
        get () {
          var status = this.props.item && this.props.item.status === 1
          return status
        },
        set (value) {
          this.$WiApiPut({
            uri: 'tasks/task',
            id: this.props.item.id,
            data: { status: value },
            callback: () => {}
          })
          this.config.refresh()
        }
      }
    },
    props: {
      config: Object,
      props: Object
    },
    components: {
      WiListDataTableActions
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .tasks__date {
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    margin: 0;
    text-align: center;
  }
  .tasks__list-tile >>> .v-list__tile {
    height: 20px !important;
    padding: 0 5px;
  }
</style>